import url from '@/resources/_url'

const head = {
  title: {
    inner: 'โปรโมชั่นของเรา TrueCoffee GO'
  },
  meta: [
    {
      id: 'description',
      name: 'description',
      content: 'โปรโมชั่นแรงสุดๆ จาก TrueCoffee GO ด้วย Buy 2 Get 1 Free! ซื้อ 2 แก้ว ฟรี 1 แก้ว! ตั้งแต่วันนี้ – 31 ธันวาคม 62 เท่านั้น สะสม True Point ได้ด้วยนะ!'
    },
    {
      id: 'og:url',
      name: 'og:url',
      content: `${url.mainUrl}/promotion`
    },
    {
      id: 'og:title',
      name: 'og:title',
      content: 'โปรโมชั่นของเรา TrueCoffee GO'
    },
    {
      id: 'og:description',
      name: 'og:description',
      content: 'โปรโมชั่นแรงสุดๆ จาก TrueCoffee GO ด้วย Buy 2 Get 1 Free! ซื้อ 2 แก้ว ฟรี 1 แก้ว! ตั้งแต่วันนี้ – 31 ธันวาคม 62 เท่านั้น สะสม True Point ได้ด้วยนะ!'
    },
    {
      id: 'og:image',
      name: 'og:image',
      content: '/meta-image.png'
    }
  ],
  link: [
    {
      id: 'canonical',
      rel: 'canonical',
      href: `${url.mainUrl}/promotion`
    }
  ]
}

export default head
